//https://www.youtube.com/watch?v=hc3CSmw3L6I
import { createStore, combineReducers } from "redux";

import initialData from "./initialdata";
import { counterReducer } from "./reducers";

const reducers = combineReducers({
  counter: counterReducer
});

export default createStore(
  (state, action) => reducers(state, action),
  initialData
);