import { Link } from "react-router-dom";
const Header = ({ session, user }) => {
    return (
        <div className="bg-secondary">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="/">Familiar <span>Swings</span></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        {session && (
                            <li className="nav-item">
                                <Link to="/dashboard" className="nav-link">Dashboard</Link>
                            </li>
                        )}
                        {session && (
                            <li className="nav-item">
                                <Link to="/investment" className="nav-link">Investment</Link>
                            </li>
                        )}
                        {(session && user.role === 'admin') && (
                            <li className="nav-item">
                                <Link to="/admin" className="nav-link">Admin</Link>
                            </li>
                        )}
                        {session && (
                            <li className="nav-item">
                                <Link to="/rule" className="nav-link">Rule</Link>
                            </li>
                        )}
                        {session && (
                            <li className="nav-item">
                                <Link to="/strategy-1" className="nav-link">Strategy 1</Link>
                            </li>
                        )}
                    </ul>
                    <div class="d-flex">
                        <div className="row">
                        <div className="col">
                            {session ? <Link to="/logout" className="btn btn-sm btn-primary me-3">Logout</Link> : <Link to="/login" className="btn btn-sm btn-primary me-3">login</Link>}
                            {!session && <Link to="signup" className="btn btn-sm btn-secondary">Signup</Link>}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </nav>
        </div>
    )
  };
export default Header;