const Footer = ({ session, user }) => {
    return (
        <div className="footer bg-secondary">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p className="p-5">Footer</p>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Footer;