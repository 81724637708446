import React, { useState, useContext, useCallback, useEffect } from "react";
import { Redirect, Link, Route, Switch, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import axios from 'axios';
import { isEmpty, upperCase, merge, sortBy } from "lodash";
import moment from "moment";
import { SocketContext, socket } from '../context/socket';
import '../App.css';
import Header from './Header';
import Footer from './Footer';
import HeroBanner from './HeroBanner';

//https://maxartkiller.com/website/Lemux/lemux-HTML/index.html
//https://themezhub.net/resido-live/resido/home-5.html

axios.defaults.baseURL = '/api/v1/';

const Home = () => {
  //const socket = useContext(SocketContext);
  useEffect(() => {
    socket.emit("abc");
  }, []);
  return (
    <h1>Home</h1>
  )
};
const Dashboard = () => {
  const [tickData, setTickData] = useState({});

  useEffect(() => {
    if(window.location.pathname === '/dashboard') {
      if(isEmpty(tickData)) {
        socket.emit('ticks');
      }
      socket.on('ticks', (data) => {
        const dump = merge(tickData, data);
        setTickData({...dump});
      });
    }
    return () => {}
  }, []);
  const isReadytoBuy = (rule, tick) => {
    const { price } = tick;
    let entryBuyPrice = '';
    Object.keys(rule).map((key) => {
      if(rule[key].price > 0 && !rule[key].skip && price > rule[key].price && price < rule[key].price + (rule[key].price * 5/100)) {
        const entryBuyPricePercentage = (Number(rule[key].price) * 1 / 100);
        const per = (Number(price) - Number(rule[key].price)) / entryBuyPricePercentage;
        entryBuyPrice += `${key}: ${rule[key].price} in ${per.toFixed(2)}%, `
      }
      return null;
    });
    return (
      <td className={!entryBuyPrice? '' : 'bg-buy'}>{entryBuyPrice}</td>
    )
  }
  const isReadytoSell = (rule, tick) => {
    const { price } = tick;
    let entrySellPrice;
    if(rule.averageBuy && rule.averageBuy > 0) {
      if(rule.sellLevel1 && price < rule.sellLevel1 &&  price > rule.sellLevel1 - (rule.sellLevel1 * 5/100)) {
        const entrySellPricePercentage = (Number(rule.sellLevel1) * 1 / 100);
        const per = (Number(rule.sellLevel1) - Number(price)) / entrySellPricePercentage;
        entrySellPrice = `L1: ${rule.sellLevel1} in ${per.toFixed(2)}%`;
      }
      if(rule.sellLevel2 && price < rule.sellLevel2 &&  price > rule.sellLevel2 - (rule.sellLevel2 * 5/100)) {
        const entrySellPricePercentage = (Number(rule.sellLevel2) * 1 / 100);
        const per = (Number(rule.sellLevel2) - Number(price)) / entrySellPricePercentage;
        entrySellPrice = `L2: ${rule.sellLevel2} in ${per.toFixed(2)}%`;
      }
      if(rule.sellLevel3 && price < rule.sellLevel3 &&  price > rule.sellLevel3 - (rule.sellLevel3 * 5/100)) {
        const entrySellPricePercentage = (Number(rule.sellLevel3) * 1 / 100);
        const per = (Number(rule.sellLevel3) - Number(price)) / entrySellPricePercentage;
        entrySellPrice = `L3: ${rule.sellLevel3} in ${per.toFixed(2)}%`;
      }
    };
    return (
      <td className={!entrySellPrice ? '' : 'bg-sell'}>{entrySellPrice}</td>
    )
  }

  const levelAndOrders = (level, tickPrice) => {
    return (
      <td className={isEmpty(level.order) ? '' : 'bg-buy-order'}>
        <span className={level.price > tickPrice ? 'low-color' : ''}>
          {level.price}
          {level.skip ? <span className="skip">X</span> : ''}
        </span>
      </td>
    )
  }
  return (
    <div>
      <h1>Dashboard</h1>
      <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Symbol</th>
              <th scope="col">Market Price</th>
              <th scope="col">Buy</th>
              <th scope="col">Sell</th>
              <th scope="col">High Price</th>
              <th scope="col">MAC1</th>
              <th scope="col">MAC2</th>
              <th scope="col">MAC3</th>
              <th scope="col">MAC4</th>
              <th scope="col">MAC5</th>
              <th scope="col">MIC High</th>
              <th scope="col">MIC1</th>
              <th scope="col">MIC2</th>
              <th scope="col">MIC3</th>
              <th scope="col">MIC4</th>
              <th scope="col">MIC5</th>
            </tr>
          </thead>
          <tbody>
            {tickData && Object.keys(tickData).map((key, index) => {
              if(isEmpty(tickData) || !tickData[key]) {
                return;
              }
              const rule = tickData[key];
              const tick = {}
              tick.price = rule.price;
              return (
                <tr>
                  <td scope="col">{index + 1}</td>
                  <td>{rule.symbol}</td>
                  <td>{tick.price}</td>
                  {isReadytoBuy(rule, tick)}
                  {isReadytoSell(rule, tick)}
                  <td className="bg-gray">{rule.HIGH}</td>
                  {levelAndOrders(rule.MAC1, tick.price)}
                  {levelAndOrders(rule.MAC2, tick.price)}
                  {levelAndOrders(rule.MAC3, tick.price)}
                  {levelAndOrders(rule.MAC4, tick.price)}
                  {levelAndOrders(rule.MAC5, tick.price)}
                  <td className="bg-gray">{rule.MICHIGH}</td>
                  {levelAndOrders(rule.MIC1, tick.price)}
                  {levelAndOrders(rule.MIC2, tick.price)}
                  {levelAndOrders(rule.MIC3, tick.price)}
                  {levelAndOrders(rule.MIC4, tick.price)}
                  {levelAndOrders(rule.MIC5, tick.price)}
                </tr>
              )
            })}
          </tbody>
      </table>
    </div>
  )
};
const Investment = () => {
  const options = [
    {value: '', text: 'Sort By'},
    {value: 'dropedPercentage', text: 'Drop Percentage'},
    {value: 'brokenMonth', text: 'Broken Month'},
    {value: 'symbol', text: 'Symbol'}
  ];
  const [tickData, setTickData] = useState({});
  const [selected, setSelected] = useState('dropedPercentage');

  useEffect(() => {
    if(window.location.pathname === '/investment') {
      if(isEmpty(tickData)) {
        socket.emit('ticks');
      }
      socket.on('ticks', (data) => {
        const dump = merge(tickData, data);
        setTickData({...dump});
      });
    }
    return () => {}
  }, []);

  const handleChange = event => {
    console.log(event.target.value);
    setSelected(event.target.value);
  };
  const getDropedPercentage = (highPrice, tickPrice) => {
    return Number((Number(100) - Number(tickPrice) / Number(highPrice) * Number(100)).toFixed(2));
  }
  const getDateDiffence = (date) => {
    const now = moment(new Date());
    const end = moment(date, 'DD/MMM/YYYY');
    const duration = moment.duration(now.diff(end));
    return Number(duration.asMonths().toFixed(0));
  }
  const fallen = (dropedPercentage) => {
    let bgColors = '';
    bgColors = `rgba(216, 92, 92, ${dropedPercentage/50})`
    return (
      <td style={{ backgroundColor: bgColors }}>
        {dropedPercentage}%
      </td>
    )
  }
  if(isEmpty(tickData)) {
    return (<div> Error in Data</div>)
  }
  const arr = [];
  tickData && Object.keys(tickData).map((key, index) => {
    if(isEmpty(tickData) || !tickData[key]) {
      return;
    }
    const rule = tickData[key];
    rule.dropedPercentage = Number(getDropedPercentage(rule.HIGH, rule.price));
    rule.brokenMonth = getDateDiffence(rule.highDate);
    arr.push(rule);
  })
  return (
    <div>
      <h1>Investment</h1>
      <div>
          <select value={selected} onChange={handleChange}>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
      </div>
      <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Symbol</th>
              <th scope="col">High Date</th>
              <th scope="col">High Price</th>
              <th scope="col">Market Price</th>
              <th scope="col">Drop</th>
              <th scope="col">Last Broken Month</th>
            </tr>
          </thead>
          <tbody>
            {
              sortBy(arr, [selected]).map((item, index) => {
                return (
                  <tr>
                    <td scope="col">{index + 1}</td>
                    <td>{item.symbol}</td>
                    <td>{item.highDate}</td>
                    <td className="bg-gray">{item.HIGH}</td>
                    <td>{item.price}</td>
                    {fallen(item.dropedPercentage)}
                    <td>{item.brokenMonth}</td>
                  </tr>
                )
              })
            }
          </tbody>
      </table>
    </div>
  )
};
const STG1 = () => {
  useEffect(() => {
  }, []);
  return (
    <div>
      <h1>Strategt - 1:</h1>
    </div>
  )
}

const Dropdown = () => {
  const options = [
    {value: '', text: '--Choose an option--'},
    {value: 'apple', text: 'Apple 🍏'},
    {value: 'banana', text: 'Banana 🍌'},
    {value: 'kiwi', text: 'Kiwi 🥝'},
  ];
  const [selected, setSelected] = useState(options[0].value);

  const handleChange = event => {
    console.log(event.target.value);
    setSelected(event.target.value);
  };
  return (
    <div>
      <select value={selected} onChange={handleChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}

const Rule = () => {
  const [report, setReport] = useState([]);
  const [symbol, setSymbol] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [period, setPeriod] = useState();
  const [errorMessage, setErrorMessage] = useState();
  let totalAverage = 0;
  const generateReport = async () => {
    totalAverage = 0;
    try {
      const res = await axios.post('/report', { symbol, from, to, period });
      if (res.data) {
        if(isEmpty(res.data)) {
          setErrorMessage('No records found...');
        }
        setReport(res.data.reverse());
      } else {
        setErrorMessage(res.data.message);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(e);
    }
  };

  useEffect(() => {
  }, []);
  return (
    <div>
      <h1>Rule Section:</h1>
      {errorMessage && <h1>{errorMessage}</h1>}
      <label>Symbol: </label>
      <br/>
      <input type="text" onChange={e => setSymbol(e.target.value)} />
      <br/>
      <label>Start Date: </label>
      <br/>
      <input type="text" placeholder="YYYY-MM-DD" onChange={e => setFrom(e.target.value)} />
      <br/>
      <label>End Date: </label>
      <br/>
      <input type="text" placeholder="YYYY-MM-DD" onChange={e => setTo(e.target.value)} />
      <br/>
      <label>Period (Daily/Weekly/Monthly): </label>
      <br/>
      <input type="text" placeholder="d/w/m" onChange={e => setPeriod(e.target.value)} />
      <br/>
      <button onClick={generateReport}>Generate</button>
      <table className="table">
        <thead>
          <tr>
              <th scope="col">#</th>
              <th scope="col">Symbol</th>
              <th scope="col">Date</th>
              <th scope="col">Close</th>
              <th scope="col">Open</th>
              <th scope="col">High</th>
              <th scope="col">Low</th>
              <th scope="col">Daily Average</th>
              <th scope="col">Average</th>
              <th scope="col">Position</th>
          </tr>
        </thead>
        <tbody>
          {report && report.map((data, index) => {
            const dailyAverage = data.low - (data.high - data.low);
            const isBuy = data.close < (totalAverage / (index));
            totalAverage += dailyAverage;
            return (
              <tr>
                <td scope="col">{index + 1}</td>
                <td scope="col">{upperCase(data.symbol)}</td>
                <td scope="col">{moment(new Date(data.date)).format('YYYY-MM-DD')}</td>
                <td scope="col">{data.close && data.close.toFixed(2)}</td>
                <td scope="col">{data.open && data.open.toFixed(2)}</td>
                <td scope="col">{data.high && data.high.toFixed(2)}</td>
                <td scope="col">{data.low && data.low.toFixed(2)}</td>
                <td scope="col">{dailyAverage.toFixed(2)}</td>
                <td scope="col">{(totalAverage / (index + 1)).toFixed(2)}</td>
                <td scope="col" className={isBuy ? 'bg-buy' : ''}>{isBuy ? 'BUY' : ''}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
};

const Login = ({ session, setSession }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const auth = async () => {
    try {
      const res = await axios.post('/authenticate', { username, password });
      if (res.data.url !== undefined) {
        window.location = res.data.url;
      } else if(res.data.isAuthenticated) {
        setSession(res.data.isAuthenticated);
      } else {
        setErrorMessage(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
  }, []);
  return (
    <div>
      {errorMessage && <h1>{errorMessage}</h1>}
      <label>Username: </label>
      <br/>
      <input type="text" onChange={e => setUsername(e.target.value)} />
      <br/>
      <label>Password: </label>
      <br/>
      <input type="password" onChange={e => setPassword(e.target.value)} />
      <br/>
      <button onClick={auth}>Login</button>
    </div>
  )
};
const Admin = () => {
  const [errorMessage, setErrorMessage] = useState();
  const startA = async () => {
    try {
      const res = await axios.get('/login');
      if (res.data.url !== undefined) {
        window.location = res.data.url;
      } else {
        setErrorMessage(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
  }, []);
  return (
    <div>
      {errorMessage && <h1>{errorMessage}</h1>}
      <button onClick={startA}>Start</button>
    </div>
  )
};
const GenerateSession = ({ session, setSession }) => {
  const parseQueryString = () => {
      const str = window.location.search;
      const objURL = {};

      str.replace(
          new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
          function( $0, $1, $2, $3 ){
              objURL[ $1 ] = $3;
          }
      );
      return objURL;
  };
  const generateSession = async (requestToken) => {
    try {
      const res = await axios.get(`/generate-session`, { params: { requestToken } });
      setSession(res.data.isAuthenticated);
    } catch (e) {
      console.log(e);
      setSession(false);
    }
  };

  useEffect(() => {
    if(!session) {
      const query = parseQueryString();
      generateSession(query.request_token);
    }
  });
  return (
    <div>
      {session ? <Redirect to="/dashboard" /> : <p>Loading....</p>}
    </div>
  )
};
const Logout = ({ session, setSession }) => {
  const deleteSession = async () => {
    try {
      const res = await axios.get(`/logout`);
      setSession(res.data.isAuthenticated);
    } catch (e) {
      console.log(e);
      setSession(false);
    }
  };

  useEffect(() => {
    deleteSession();
  });
  return (
    <div>
      <p>Loading....</p>
      {session ? <p>Loading....</p> : <Redirect to="/login" />}
    </div>
  )
};

const ErrorPage = () => {
  return (
    <h1>ErrorPage</h1>
  )
};

const notFound = () => {
  return (
    <h1>notFound</h1>
  )
};

function App() {
  // const socket = useContext(SocketContext);
  const [user, setUser] = useState({});
  const [session, setSession] = useState(false);

  const [socketConnection, setSocketConnection ] = useState(false);
  const [socketConnectionMessage, setSocketConnectionMessage ] = useState('Connecting Server...');
  const [notification, setNotification ] = useState();
  const [showNotification, setShowNotification ] = useState(false);

  useEffect(() => {
    // Global events are bound against socket
    socket.on('connect_failed', () => {
      setSocketConnection(false);
      setSocketConnectionMessage('Server connection failed...');
    });
    socket.on('connect', () => {
      setSocketConnection(true);
      setSocketConnectionMessage('Connected Sucessfully...');
    });
    socket.on('disconnect', () => {
      setSocketConnection(false);
      setSocketConnectionMessage('Unable to connect Server...');
    });
    socket.on('notification', (data) => {
      setNotification(data);
      setShowNotification(true)
    });
  });
  useEffect(() => {
    const getSession = async () => {
      try {
        const res = await axios.get('/session');
        setSession(res.data.isAuthenticated);
      } catch (e) {
        console.log(e);
        setSession(false);
      }
    }    
    const getUser = async () => {
      try {
        const res = await axios.get('/users');
        setUser(res.data);
      } catch (e) {
        console.log(e);
      }
    }
    getSession();
    getUser();
  }, [setSession, setUser]);

  return (
    <SocketContext.Provider value={socket}>
      <div className="container-fluid g-0">
          <Header session={session} user={user}/>
          <div className="bg-primary">
            <div className="container">
              <div className="row justify-content-center">
                  <div className="col-8 col-sm-12 text-center"><h1 className="p-5">Find Your Perfect Place</h1></div>
              </div>
            </div>
          </div>
          <div className="main-content">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <Switch>
                      <Route exact={true} path="/" component={Home} />
                      <Route exact={true} path="/login">
                        {session ? <Redirect to="/dashboard" /> : <Login setSession={setSession} session={session} />}
                      </Route>
                      <Route exact={true} path="/logout">
                        <Logout setSession={setSession} session={session} />
                      </Route>
                      <Route exact={true} path="/dashboard">
                        {session ? <Route component={Dashboard} /> : <Redirect to="/login" />}
                      </Route>
                      <Route exact={true} path="/investment">
                        {session ? <Route component={Investment} /> : <Redirect to="/login" />}
                      </Route>
                      <Route exact={true} path="/admin">
                        {session ? <Route component={Admin} /> : <Redirect to="/login" />}
                      </Route>
                      <Route exact={true} path="/rule">
                        {session ? <Route component={Rule} /> : <Redirect to="/login" />}
                      </Route>
                      <Route exact={true} path="/strategy-1">
                        {session ? <Route component={STG1} /> : <Redirect to="/login" />}
                      </Route>
                      {/*<Route exact={true} path="/generate/session">
                        {session ? <Redirect to="/dashboard" /> : <GenerateSession setSession={setSession} session={session} />}
                      </Route> */}
                      <Route path="/error" component={ErrorPage} />
                      <Route component={notFound} />
                    </Switch>
                  </div>
                </div>
              </div>
          </div>
          <Footer />
      </div>
      <div>
        {showNotification && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h1>{notification.message}</h1>
          </div>
        )}
        {!socket.connected && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h1>{socketConnectionMessage}</h1>
          </div>
        )}
      </div>
    </SocketContext.Provider>
  );
}

export default App;
