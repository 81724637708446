import React from "react";
import socketio from "socket.io-client";

// export const socket = io('ws://localhost:8080/socket.io', { }); // io('ws://localhost:8080/api/v1/');// socketio.connect('ws://localhost:8080/api/v1/');

/* export const socket = io('http://localhost:8000', {     // note changed URL here
    path: '/socket',
    transports: ['websocket']
}); */

export const socket = socketio.connect('');
 
export const SocketContext = React.createContext();